import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VFileInput } from 'vuetify/lib/components/VFileInput';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VWindow } from 'vuetify/lib/components/VWindow';
import { VWindowItem } from 'vuetify/lib/components/VWindow';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('route-dialog',{attrs:{"title":_vm.dialogTitle,"confirm-text":_vm.confirmText},on:{"confirm":_vm.onConfirm}},[_c('template',{slot:"title"},[_c('span',[_vm._v(_vm._s(_vm.dialogTitle))]),_c(VAvatar,{staticClass:"subheading white--text",attrs:{"color":"primary lighten-2","size":"24"},domProps:{"textContent":_vm._s(_vm.step)}})],1),_c(VWindow,{model:{value:(_vm.step),callback:function ($$v) {_vm.step=$$v},expression:"step"}},[_c(VWindowItem,{attrs:{"value":1}},[_c('p',[_vm._v(" Use this dialog to import/update a batch of charge points. The E-Connector expects a comma separated csv file. Unknown charge points will automatically be created, existing ones will be updated. Click "),_c('a',{attrs:{"href":"/api/chargepoints/import"}},[_vm._v("here")]),_vm._v(" to download the csv template. ")]),_c(VFileInput,{attrs:{"accept":"text/csv","placeholder":"Pick a csv","label":"Import","show-size":""},model:{value:(_vm.file),callback:function ($$v) {_vm.file=$$v},expression:"file"}})],1),_c(VWindowItem,{attrs:{"value":2}},[_c('p',[_vm._v(" The import was successful. In total "+_vm._s(_vm.imported.length)+" charge points got imported/updated. There were "+_vm._s(_vm.failed.length)+" failed imports."),_c('br'),_vm._v(" A more detailed overview is shown in the table below. ")]),_c(VTabs,[_c(VTab,[_vm._v("Successful imports")]),_c(VTab,[_vm._v("Failed imports")]),_c(VTabItem,{attrs:{"value":0}},[_c(VDataTable,{attrs:{"headers":_vm.importedHeaders,"items":_vm.imported,"no-data-text":"No successful imports to show"}})],1),_c(VTabItem,{attrs:{"value":1}},[_c(VDataTable,{attrs:{"headers":_vm.failedHeaders,"items":_vm.failed,"no-data-text":"No failed imports to show"}})],1)],1)],1)],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }