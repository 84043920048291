
import axios from 'axios';

import {Component, Vue} from "vue-property-decorator";
import RouteDialog from "@/components/RouteDialog.vue";
import {FailedChargePoint, ImportedChargePoint, ImportedChargePointsResponse, RouteDialogConfirmEvent} from "@/domain";
import {DataTableHeader} from "vuetify";

const enum ImportStep {
    UPLOAD_IMPORT = 1,
    IMPORT_SUCCESS = 2
}

@Component({
    components: {RouteDialog}
})
export default class ImportChargePointsDialog extends Vue {
    step: ImportStep = ImportStep.UPLOAD_IMPORT;
    file: File | null = null;

    imported: ImportedChargePoint[] = [];
    failed: FailedChargePoint[] = [];

    importedHeaders: DataTableHeader[] = [{
        value: 'name',
        text: 'Charge Point'
    }, {
        value: 'centralSystemName',
        text: 'Central System'
    }, {
        value: 'linkType',
        text: 'Link Type'
    }, {
        value: 'customName',
        text: 'Custom Name'
    }];

    failedHeaders: DataTableHeader[] = [{
        value: 'chargePoint.name',
        text: 'Charge Point'
    }, {
        value: 'chargePoint.centralSystemName',
        text: 'Central System'
    }, {
        value: 'error',
        text: 'Error'
    }];

    get dialogTitle() {
        switch (this.step) {
            case ImportStep.UPLOAD_IMPORT:
                return 'Import Charge Points';
            case ImportStep.IMPORT_SUCCESS:
                return 'Review imported Charge Points';
        }

        return '';
    }

    get confirmText() {
        switch (this.step) {
            case ImportStep.UPLOAD_IMPORT:
                return 'Import';
            case ImportStep.IMPORT_SUCCESS:
                return 'Done';
        }

        return '';
    }

    async onConfirm(evt: RouteDialogConfirmEvent) {
        // Upload csv to backend
        if (this.step === ImportStep.UPLOAD_IMPORT && this.file) {

            const formData = new FormData();
            formData.append('file', this.file, this.file.name);

            const res = await axios.post<ImportedChargePointsResponse>('/api/chargepoints/import', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            this.imported = res.data.imported;
            this.failed = res.data.failed;

            evt.cancel();

            this.step = ImportStep.IMPORT_SUCCESS;

            return;
        }

        // User has seen the imports, close dialog.
        if (this.step === ImportStep.IMPORT_SUCCESS) {
            evt.done();
            return;
        }

        evt.cancel();
    }
}
